.header__reassurance {
    @apply px-28 hidden lg:block;
}

.header__reassurance {
    background-color: #1269A2 ;
}

.header__reassurance__wrapper {
    @apply flex justify-between py-4 text-base;
}

.header__reassurance__wrapper div {
    @apply flex text-white fill-current gap-x-3;
}

.header__reassurance__wrapper div svg {
    @apply my-auto;
}
