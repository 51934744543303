@import "~@glidejs/glide/dist/css/glide.core.min.css";
@import "~@glidejs/glide/dist/css/glide.theme.min.css";
@import '~intl-tel-input/build/css/intlTelInput.css';

@import "menu/menu.css";
@import "menu/reassurance.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

.glide__arrows .glide__arrow--left {
    @apply !left-12 bg-white rounded-full opacity-60 text-black font-semibold text-2xl leading-none p-8 opacity-60 text-black;
}

.glide__arrows .glide__arrow--right {
    @apply !right-12 bg-white rounded-full opacity-60 text-black font-semibold text-2xl leading-none p-8 opacity-60 text-black;
}

.glide__bullet {
    @apply !rounded-lg !w-10 !h-1.5;
}

.glide__bullet--active {
    @apply !bg-white !w-20;
}

.scrolled, .scrollDown {
    @apply border-b border-black;
}


@layer base {
    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Thin.ttf) format('ttf');
        font-weight: 100;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Light.ttf) format('ttf');
        font-weight: 200;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Regular.ttf) format('ttf');
        font-weight: 400;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Medium.ttf) format('ttf');
        font-weight: 500;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-SemiBold.ttf) format('ttf');
        font-weight: 600;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Bold.ttf) format('ttf');
        font-weight: 700;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-ExtraBold.ttf) format('ttf');
        font-weight: 800;
    }

    @font-face {
        font-family: Inter;
        src: url(../fonts/Inter/Inter-Black.ttf) format('ttf');
        font-weight: 900;
    }

    .label {
        @apply p-1 px-2 rounded-md text-white bg-sc-blue text-sm whitespace-nowrap;
    }

    .glide__slide {
        @apply whitespace-nowrap;
    }

    .glide__slide .label {
        @apply w-full text-center;
    }
    .slider--map .glide__slide {
        height: unset;
    }

    .tab-item {
        @apply border-transparent text-sc-blue hover:border-darker-blue inline-flex items-center py-4 px-8 border-b-2 font-bold cursor-pointer
    }

    .tab-item-active {
        @apply border-cyan text-cyan hover:border-cyan border-b-2;
    }

    .tab-item-active svg {
        @apply text-cyan;
    }

    .pricing-table-cell {
        @apply flex flex-col justify-center text-center text-2xl font-bold hover:bg-gray-100 border-gray-200 border-2 rounded-lg;
        height: 5.0625rem;
    }

    .empty-pricing-table-cell {
        @apply flex flex-col justify-center text-center text-lg text-red-500 font-bold;
        height: 5.0625rem;
    }

    .steps-table th {
        @apply text-center font-bold p-1.5 border-r border-gray-200;
    }

    .steps-table th:last-child {
        @apply border-r-0;
    }

    .steps-table tbody tr td {
        @apply text-center border-t border-r border-gray-200 p-1.5;
    }

    .steps-table tbody tr td:last-child {
        @apply border-r-0;
    }

    .steps-table tbody tr td:nth-child(2) {
        @apply text-left;
    }

    .ship-details-table th {
        @apply text-left p-3 border-gray-200 font-normal p-3 border-t;
    }

    .ship-details-table td {
        @apply text-right border-t border-gray-200 p-3 font-semibold;
    }

    .ship-details-table tbody tr:first-child td, .ship-details-table tbody tr:first-child th {
        @apply border-t-0;
    }

    nav.tabs {
        @apply -mb-px flex space-x-8;
    }

    .tabs .tab {
        @apply border-transparent text-darker-blue font-bold hover:border-yellow-300 whitespace-nowrap py-2 px-1 border-b-4 text-lg cursor-pointer;
    }

    .tabs .tab.active {
        @apply border-yellow-300;
    }

    [class*="carousel--"] .glide__arrows .glide__arrow--left {
        @apply !-left-24;
    }

    [class*="carousel--"] .glide__arrows .glide__arrow--right {
        @apply -right-24;
    }

    [class*="carousel--companies"] .glide__arrows .glide__arrow--left {
        @apply !-left-16;
    }

    [class*="carousel--companies"] .glide__arrows .glide__arrow--right {
        @apply !-right-16;
    }

    .glide--slider .glide__slide--hp {
        @apply object-cover min-w-full md:h-[624px];
    }

    .glide__arrows {
        @apply invisible lg:visible;
    }

    .glide__arrows .glide__arrow--left {
        @apply !left-12 bg-white rounded-full opacity-60 text-black font-semibold text-2xl leading-none;
    }

    .glide__arrows .glide__arrow--right {
        @apply !right-12 bg-white rounded-full opacity-60 text-black font-semibold text-2xl leading-none;
    }

    .glide__bullet {
        @apply !rounded-lg !w-10 !h-1.5;
    }

    .glide__bullet--active {
        @apply !bg-white !w-20;
    }

    .carousel--home .action {
        @apply bg-azur-blue hover:bg-yellow-300 rounded-full text-center py-4 px-8 lg:py-2 px-5 lg:py-5 lg:px-12 font-bold;
    }

    .block__button {
        @apply rounded-full py-3 px-5 lg:px-8 border-2 bg-transparent font-bold
    }

    #quote-creation-panel label {
        font-size: 0.9rem;
        @apply font-normal
    }

    #quote-creation-panel a.disabled {
        @apply text-gray-200
    }

    .grouped-form {
        @apply mt-5 w-full mx-auto border border-gray-200
    }

    .grouped-form > .form-entry {
        @apply px-4 pt-4 pb-3.5 border-b border-gray-200
    }

    .grouped-form > .form-entry:last-child {
        @apply border-b-0 pb-2.5
    }

    .grouped-form > .form-entry > label {
        @apply block text-xs mb-1.5 font-normal
    }

    .grouped-form > .form-entry > .input-wrapper, .grouped-form > .form-entry > .input-tel-wrapper {
        @apply overflow-hidden
    }

    .grouped-form > .form-entry > .input-wrapper > input, .grouped-form > .form-entry > .input-tel-wrapper > input, .grouped-form > .form-entry > .input-wrapper select {
        @apply outline-none block w-full border-gray-300 border-0 p-0 m-0 placeholder-gray-400
    }

    .grouped-form > .form-entry > .input-wrapper > textarea {
        @apply outline-none block w-full border-gray-300 border-0 p-0 m-0 placeholder-gray-400
    }

    .grouped-form > .form-entry > .input-tel-wrapper .iti__selected-flag {
        @apply outline-none;
        background: none;
        margin-top: 1px;
    }

    .checkbox-wrapper {
        @apply mt-5 flex flex-row
    }

    .checkbox-wrapper input[type="checkbox"] {
        @apply rounded-md border-gray-300 w-6 h-6 mr-3
    }

    .checkbox-wrapper label {
        @apply text-base font-light
    }

    .yellow-button {
        @apply inset-y-1 right-3 h-14 my-auto text-white font-bold bg-yellow-400 px-9
    }

    .yellow-button:disabled {
        @apply bg-yellow-200 cursor-default
    }

    .img-zoom {
        @apply rounded-xl mx-auto min-w-full object-cover object-top transition duration-150 ease-in-out group-hover:transform group-hover:scale-105
    }
}

.popover:hover .popover__text {
    visibility: visible;
}

.step-next-button {
    @apply h-14 my-auto text-white font-bold bg-yellow-400
}

.step-next-button:disabled {
    @apply bg-yellow-200 cursor-default
}

.popover__text:before {
    position: absolute;
    content: "";
    right: calc(50% - 10px);
    top: -8px;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #ffffff transparent;
    transition-duration: 0.3s;
    transition-property: transform;
}

[x-cloak] {
    display: none;
}

.slider--search-date .glide__slide--active {
   @apply bg-sc-blue border-sc-blue text-white
}

.font-bold-500 {
    font-weight: 500 !important;
}

.font-bold-600 {
    font-weight: 600 !important;
}

.seo-content__text {
    @apply space-y-4 overflow-hidden;
    max-height: 11em;
}

.seo-content__text.shown {
    @apply max-h-full transition-height delay-1000
}

.seo-content h2 {
    @apply !text-2xl lg:!text-3xl !text-dark-blue;
}

.seo-content h2 {
    font-weight: 500;
}

.seo-content h3 {
    @apply font-bold text-lg;
}
.seo-content__text a {
    @apply text-azur-blue underline
}

.max-h-156 {
    max-height: 39rem;
}

.gradiant-black {
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(50%,transparent),to(rgba(0,0,0,.9)));
    background-image: linear-gradient(
        -180deg
        ,transparent 50%,rgba(0,0,0,.9));
}

.gradiant-black-full {
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(100%,transparent),to(rgba(0,0,0,.3)));
    background-image: linear-gradient(
        -180deg
        ,transparent 0%,rgba(0,0,0,.3));
}

.gradiant-black-cruise {
    background-image: -webkit-gradient(linear,left top,left bottom,color-stop(100%,transparent),to(rgba(0,0,0,.4)));
    background-image: linear-gradient(
        0deg
        ,transparent 0%,rgba(0,0,0,.4));
}

/*Livewire Styles*/
[wire\:loading], [wire\:loading\.delay], [wire\:loading\.inline-block], [wire\:loading\.inline], [wire\:loading\.block], [wire\:loading\.flex], [wire\:loading\.table], [wire\:loading\.grid] {
    display: none;
}

[wire\:offline] {
    display: none;
}

[wire\:dirty]:not(textarea):not(input):not(select) {
    display: none;
}

input:-webkit-autofill, select:-webkit-autofill, textarea:-webkit-autofill {
    animation-duration: 50000s;
    animation-name: livewireautofill;
}

.review_ratingValue {
    height: 0;
    overflow: hidden;
}

.sticky-content .sticky-text {
    line-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display:block;
}

.sc-orderCenter-info  {
    background-color: rgb(255, 255, 255);
    font-weight: bold;
}

.ellipsis {
    line-height: 25px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    display: block;
}

.sc-homeReview .sc-homeReview-textContent {
    height: 7.3rem;
    overflow: hidden;
}

.sc-glideShipCabins .glide__bullets {
    bottom: 5rem;
}

.slider--map .glide__bullet {
    @apply w-3 h-3;
}

.slider--map .glide__bullets {
    bottom: 1em;
}

.sc-modal {
    padding-top:15px;
}

a.sc-team-button {
    cursor:pointer;
}

body {
    @apply font-sans;
}

body .sc-headerFilter--position {
    top: 58px;
}

.sc-headerFilter {
    position:sticky;
    z-index:5;
    top: 0;
}

.sc-search-content {
    overflow: hidden;
}

.sc-search-offerDayBandeau {
    position: absolute;
    font-weight: bold;
    top: -46px;
    left: -55px;
    background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
    font-size: 16px;
    color: #fff;
    padding: 54px 103px 0 40px;
    -ms-transform: rotate(-30deg);
    -webkit-transform: rotate(-30deg);
    transform: rotate(-30deg);
}

.sc-search-fromText {
    font-size: 1rem;
}

.sc-search-content {
    border: 1px solid #778899;
}

.sc-bestCruise-subtitle p, a.sc-bestCruise-link {
    text-shadow: 4px 3px 10px black;
    font-weight: bold;
}

.sc-quote-adultChild select, .sc-quote-nameFistname input {
    @apply border-0 focus:outline-none focus:shadow-none focus:ring-offset-0;
    --tw-ring-shadow: 0;
}

.sc-searchFilterCompanyError--display {
    @apply hidden;
}

.sc-searchPage-advantagesIcons {
    line-height: 1.28rem !important;
    font-size: unset !important;
}

.sc-searchPage-cruiseButton {
    width: 100%;
}

.sc-searchPage-img {
    min-height: 130px;
    height: auto;
}

.sc-order .material-icons {
    font-size: 1.125rem;
    margin-top: 0.375rem;
}

@keyframes livewireautofill { from {} }

.headerSearch-subtitle--textColor {
    @apply text-yellow-500 font-bold;
}

.sc-searchBloc {
    max-width: 430px;
}

.modal__vertical-align {
    padding-bottom: 15%;
}

.sc-searchBloc-content {
    max-width : 80%;
}

.sc-search-advantage {
    font-weight: bold;
}

.sc-search-advantage span {
    font-size: 15px;
}

.sc-carouselDiscover .glide__slide p {
    overflow: hidden;
}

.sc-carouselDiscover .glide__arrows .glide__arrow--left {
    @apply !-left-16 !top-28 p-2;
}

.sc-carouselDiscover .glide__arrows .glide__arrow--right {
    @apply !top-28 p-2 !-right-16;
}

.sc-faq p {
    @apply mb-3;
}

.sc-faq ul {
    list-style: unset;
    padding-left: 40px;
}

.line-through {
    text-decoration: line-through;
}

.opacity-8 {
    opacity: 0.8;
}

.h-80 {
    height: 22rem;
}

.hover\:opacity-60:hover{
    opacity: 0.6;
}

.cruise-offers__subtitle__link {
    @apply flex gap-x-6 hover:underline !text-primary;
}


.cruise-offers__subtitle__link div {
    @apply my-auto fill-current
}

a#CybotCookiebotDialogPoweredbyCybot,
div#CybotCookiebotDialogPoweredByText {
    display: none;
}


@media only screen and (min-device-width: 481px) and (max-device-width: 1419px) and (orientation:landscape) {
    /* For landscape layouts only */
    .menu--desktop .menu__phone .menu__phone-number {
        display: none;
    }
}

@media only screen and (device-width: 768px) {
    .sc-homepageVacation .discover a,
    .sc-homepageDiscover .sc-homepageDiscover-content,
    .sc-homepageMedia .sc-homepageMedia-content {
        width: 40%;
        display:block;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1023px) and (orientation:portrait) {
    /* For portrait layouts only */
    .sc-homepageVacation .discover a,
    .sc-homepageDiscover .sc-homepageDiscover-content,
    .sc-homepageMedia .sc-homepageMedia-content {
        width: 40%;
        display:block;
    }
}

@media only screen and (min-device-width: 481px) and (max-device-width: 1023px) and (orientation:landscape) {
    /* For landscape layouts only */
    .sc-homepageVacation .discover a,
    .sc-homepageDiscover .sc-homepageDiscover-content,
    .sc-homepageMedia .sc-homepageMedia-content {
        width: 40%;
        display: block;
    }
}
