.menu--desktop {
    @apply hidden lg:flex w-full px-28 pt-4 py-4 top-0;
}

.menu--landskape {
    @apply hidden w-full py-2 pl-3;
}

.menu--desktop {
    color: #1269A2 ;
}

.menu--desktop .menu__logo {
    @apply w-52;
}

.menu--desktop .menu__nav ul {
    @apply list-none flex gap-x-4 xl:gap-x-10 justify-center;
}

.menu--desktop .menu__nav {
    @apply m-auto text-center;
}

.menu--desktop .menu__phone {
    @apply flex gap-x-3;
}

.menu--desktop .menu__phone-icon,
.menu--landskape .menu__phone-icon {
    @apply p-2;
}

.menu--desktop .menu__phone-icon svg,
.menu--landskape .menu__phone-icon svg {
   fill:white;
}

.menu--desktop .menu__phone-icon,
.menu--landskape .menu__phone-icon {
    background-color: #FBC756;
}

.menu--desktop .menu__phone-number,
.menu--landskape .menu__phone-number {
    @apply my-auto font-semibold text-lg;
}

.menu--desktop .menu__nav__dropdown {
    @apply cursor-pointer flex gap-x-3;
}

.menu--desktop .menu__nav__dropdown svg {
    @apply my-auto
}

.menu--desktop .menu__nav__dropdown__block {
    @apply absolute left-1/2 transform -translate-x-1/2 mt-8 px-2 w-screen max-w-xs sm:px-0;
}

.menu--desktop .menu__nav__dropdown__block__bg {
    @apply shadow-lg ring-1 ring-black ring-opacity-5;
}

.menu--desktop .menu__nav__dropdown__link__wrapper {
    @apply relative grid gap-6 bg-white px-5 py-6 sm:gap-8 sm:p-8;
}

.menu--desktop .menu__nav__dropdown__link {
    @apply -m-3 p-3 block hover:bg-gray-50;
}

.menu--mobile {
    @apply lg:hidden grid grid-cols-2 gap-x-4 px-8 py-4 sticky;
}

.menu--mobile .menu--mobile__nav {
    @apply flex justify-end gap-x-4;
}

.menu--mobile .menu__phone-icon, .menu__phone-icon {
    @apply my-auto;
}
